import { CALL_API_METHODS } from "repoV2/constants/apis";
import { callApi } from "repoV2/utils/common/api/callApi";
import { prependBackendAPIHost } from "repoV2/utils/common/api/api";
import { IUser } from "@Interfaces/redux/store/IUser";
import { THANKYOU_PAGE_API_URLS } from "./thankyou.api.constants";
import {
    GET_DEFAULT_SECTIONS_DATA,
    INITIAL_SECTIONS_DATA,
    THANKYOU_PAGE_SECTION_SLUGS,
} from "../constants/thankyou.contants";
import {
    IThankyouPagesPreBookingQuesData,
    IThankyouPagesSectionsData,
} from "../types/IThankyouPages";
import { processTestimonialList } from "./thankyou.api.utils";
import {
    IGetEventDataProps,
    IGetHostDataProps,
    IGetThankyouPgSectionData,
    IGetThankyouPgTransactionDataArgs,
} from "./thankyou.api.interface";
import { getThankyouPageTypeBasedOnValue } from "../utils";

export const getProcessedThankyouPgSectionData = ({
    listingUuid,
    hostName,
    params,
}: IGetThankyouPgSectionData) =>
    callApi({
        url: prependBackendAPIHost(
            `${THANKYOU_PAGE_API_URLS.GET_SECTION_DATA({
                listingUuid,
                hostName,
            })}`
        ),
        method: CALL_API_METHODS.GET,
        params,
        extraOptions: {
            appendAuthHeaders: true,
            appendTimezoneHeaders: true,
            appendExlyOriginHeaders: true,
            appendJwtTokenHeaders: true,
        },
    }).then((response: any) => {
        const thankyouPageUuid = response?.data?.data.uuid;
        const unprocessedData = response?.data?.data?.sections;
        const PAGE_TYPE =
            getThankyouPageTypeBasedOnValue(
                +(params?.page_type || "0") as keyof typeof params
            ) || 0;
        // TODO: remove
        // console.log("unprocessedData", unprocessedData);
        const sectionsData: IThankyouPagesSectionsData = JSON.parse(
            JSON.stringify(INITIAL_SECTIONS_DATA)
        );
        unprocessedData.forEach((i: any = {}) => {
            const unprocessedSectionData = i.data.section_data;
            if (unprocessedSectionData) {
                switch (i.slug) {
                    case THANKYOU_PAGE_SECTION_SLUGS.CONFIRMATION:
                        sectionsData.confirmation = {
                            uuid: i.data.uuid,
                            title: unprocessedSectionData.title,
                            description: unprocessedSectionData.description,
                        };
                        break;
                    case THANKYOU_PAGE_SECTION_SLUGS.REDIRECTION:
                        sectionsData.redirection = {
                            uuid: i.data.uuid,
                            ctaText: unprocessedSectionData.ctaText,
                            redirectionUrl:
                                unprocessedSectionData.redirectionUrl,
                            btnType: unprocessedSectionData.selectedBtnType,
                            isAutoRedirectEnabled:
                                unprocessedSectionData.isAutoRedirectEnabled,
                        };
                        break;
                    case THANKYOU_PAGE_SECTION_SLUGS.UPSELL:
                        sectionsData.upsell = {
                            uuid: i.data.uuid,
                            upsellOfferingId:
                                unprocessedSectionData.upsell_offering_uuid,
                            upsellOfferingType:
                                unprocessedSectionData.upsell_listing_type,
                            title: unprocessedSectionData.title,
                            subTitle: unprocessedSectionData.subtitle,
                            description: unprocessedSectionData.description,
                            offerText: unprocessedSectionData.offer_text,
                            mediaType: unprocessedSectionData.media_type,
                            mediaImgSrc: unprocessedSectionData.cover_image,
                            mediaVideoSrc: unprocessedSectionData.cover_video,
                            purchaseText:
                                unprocessedSectionData.purchase_button_cta,
                            cancelText:
                                unprocessedSectionData.cancel_button_cta,
                        };
                        break;
                    case THANKYOU_PAGE_SECTION_SLUGS.TESTIMONIAL:
                        sectionsData.testimonial = {
                            uuid: i.data.uuid,
                            title: unprocessedSectionData.title,
                            listDesign: unprocessedSectionData.listDesign,
                            testimonialList: processTestimonialList(
                                unprocessedSectionData.data
                            ),
                        };
                        break;
                    case THANKYOU_PAGE_SECTION_SLUGS.ANALYTICS:
                        sectionsData.analytics = {
                            event:
                                unprocessedSectionData.event ||
                                INITIAL_SECTIONS_DATA.analytics.event,
                        };
                        break;
                    default:
                        break;
                }
            } else {
                switch (i.slug) {
                    case THANKYOU_PAGE_SECTION_SLUGS.CONFIRMATION:
                        sectionsData.confirmation = {
                            uuid: i.data.uuid,
                            ...GET_DEFAULT_SECTIONS_DATA({}).confirmation[
                                PAGE_TYPE
                            ],
                        };
                        break;
                    case THANKYOU_PAGE_SECTION_SLUGS.REDIRECTION:
                        sectionsData.redirection = {
                            uuid: i.data.uuid,
                            ...GET_DEFAULT_SECTIONS_DATA({}).redirection[
                                PAGE_TYPE
                            ],
                        };
                        break;
                    case THANKYOU_PAGE_SECTION_SLUGS.UPSELL:
                        sectionsData.upsell = {
                            uuid: i.data.uuid,
                            ...GET_DEFAULT_SECTIONS_DATA({}).upsell,
                        };
                        break;
                    case THANKYOU_PAGE_SECTION_SLUGS.TESTIMONIAL:
                        sectionsData.testimonial = {
                            uuid: i.data.uuid,
                            ...GET_DEFAULT_SECTIONS_DATA({}).testimonial,
                        };
                        break;
                    default:
                        break;
                }
            }
        });
        // TODO: remove
        // console.log("processedSectionsData", sectionsData);
        return {
            sectionsData,
            thankyouPageUuid,
        };
    });

export const getEventData = ({
    hostName,
    listingUuid,
    params,
}: IGetEventDataProps) =>
    callApi({
        url: THANKYOU_PAGE_API_URLS.GET_EVENT_DATA({
            hostName,
            eventId: listingUuid,
        }),
        method: CALL_API_METHODS.GET,
        params,
        extraOptions: {
            appendAuthHeaders: true,
            appendTimezoneHeaders: true,
            appendExlyOriginHeaders: true,
            appendJwtTokenHeaders: true,
        },
    }).then((response: any) => {
        const { event } = response?.data?.data;
        return event;
    });

export const getHostData = ({ hostName, params }: IGetHostDataProps) =>
    callApi({
        url: THANKYOU_PAGE_API_URLS.GET_HOST_DATA({ hostName }),
        method: CALL_API_METHODS.GET,
        params,
        extraOptions: {
            appendAuthHeaders: true,
            appendTimezoneHeaders: true,
            appendExlyOriginHeaders: true,
            appendJwtTokenHeaders: true,
        },
    }).then((response: any) => {
        const host = response?.data?.data;
        return host;
    });

export type IGetThankyouPgTransactionDataResponse = {
    transaction: any;
    bookingQuestions: IThankyouPagesPreBookingQuesData;
    telegramData: IUser.IOrderTelegramData;
    whatsappCommunityLink?: string;
};
/**
 * @deprecated Please use the util defined at repoV2/features/ThankYouPages/utils/callGetTransactionDetailsApi.ts
 */
export const getThankyouPgTransactionData = ({
    transactionId,
    params,
}: IGetThankyouPgTransactionDataArgs): Promise<IGetThankyouPgTransactionDataResponse> =>
    callApi({
        url: THANKYOU_PAGE_API_URLS.GET_TRANSACTION_STATUS({ transactionId }),
        method: CALL_API_METHODS.GET,
        params,
        extraOptions: {
            appendAuthHeaders: true,
            appendTimezoneHeaders: true,
            appendExlyOriginHeaders: true,
            appendJwtTokenHeaders: true,
        },
    }).then((response: any) => {
        const { order, booking_questions, telegram_data, wa_community_link } =
            response?.data?.data;
        return {
            transaction: order,
            bookingQuestions: booking_questions,
            telegramData: telegram_data,
            whatsappCommunityLink: wa_community_link,
        };
    });

export const getThankyouPgCustomCodes = ({
    listingUuid = "",
    hostName = "",
    pageType,
}: {
    listingUuid: string | undefined;
    hostName: string | undefined;
    pageType: number | undefined;
}) =>
    callApi({
        url: prependBackendAPIHost(
            `${THANKYOU_PAGE_API_URLS.GET_SECTION_DATA({
                listingUuid,
                hostName,
            })}`
        ),
        method: CALL_API_METHODS.GET,
        params: { ...(pageType && { page_type: pageType }) },
        extraOptions: {
            appendAuthHeaders: true,
            appendTimezoneHeaders: true,
            appendExlyOriginHeaders: true,
            appendJwtTokenHeaders: true,
        },
    }).then((response: any) => {
        const { custom_codes } = response?.data?.data;
        return custom_codes;
    });
