import { CUSTOM_QUESTION_TYPES_ID } from "repoV2/utils/getInTouchForm/getInTouchForm.constants";
import { SESSION_STORAGE_KEYS as session_storage_keys } from "repoV2/constants/common/storage/sessionStorage";
import { format } from "date-fns";
import { ANALYTICS_EVENTS } from "repoV2/features/Common/Analytics/Analytics.constants";
// @ts-ignore
import { THANKYOU_PAGE_SECTION_SLUGS as _THANKYOU_PAGE_SECTION_SLUGS } from "@my-scoot/exly-react-component-lib/build/cjs/features/ThankYouPages/ThankYouPages.constants";
import { THANKYOU_PAGE_SECTION_SLUGS as THANKYOU_PAGE_SECTION_SLUGS_TYPE } from "@my-scoot/exly-react-component-lib/build/types/features/ThankYouPages/ThankYouPages.constants";
import { IThankyouPagesArgs } from "../types/IThankyouPages";

export const DEFAULT_REDIRECTION_TIMER_IN_MS = 5000;

export const THANKYOU_PAGE_TYPES = {
    OPTIN: "optin",
    PURCHASE: "purchase",
};

export const THANKYOU_PAGE_TYPES_VALUE = {
    [THANKYOU_PAGE_TYPES.PURCHASE]: 1,
    [THANKYOU_PAGE_TYPES.OPTIN]: 2,
};
export const BOOKING_DETAILS_FIELD_KEYS = {
    name: "customer_name",
    phone: "customer_phone",
    email: "customer_email",
    offeringName: "listing_title",
    price: "amount",
    transactionDate: "created_at",
    transactionId: "order_id",
    billingState: "billing_state",
};

export const BOOKING_DETAILS_LABELS_MAP: { [key: string]: string } = {
    [BOOKING_DETAILS_FIELD_KEYS.name]: "Name",
    [BOOKING_DETAILS_FIELD_KEYS.phone]: "Phone Number",
    [BOOKING_DETAILS_FIELD_KEYS.email]: "Email",
    [BOOKING_DETAILS_FIELD_KEYS.offeringName]: "Offering name",
    [BOOKING_DETAILS_FIELD_KEYS.price]: "Price",
    [BOOKING_DETAILS_FIELD_KEYS.transactionDate]: "Transaction date",
    [BOOKING_DETAILS_FIELD_KEYS.transactionId]: "Transaction ID",
    [BOOKING_DETAILS_FIELD_KEYS.billingState]: "Billing state",
};

export const FORM_SUMMARY_FIELD_KEYS = {
    full_name: "full_name",
    phone_number: "phone_number",
    email: "email",
    date_n_time: "date_n_time",
    listing_name: "listing_name",
    message: "message",
};

export const FORM_SUMMARY_LABELS_MAP: { [key: string]: string } = {
    [FORM_SUMMARY_FIELD_KEYS.full_name]: "Name",
    [FORM_SUMMARY_FIELD_KEYS.phone_number]: "Phone Number",
    [FORM_SUMMARY_FIELD_KEYS.email]: "Email",
    [FORM_SUMMARY_FIELD_KEYS.date_n_time]: "Date & Time",
    [FORM_SUMMARY_FIELD_KEYS.listing_name]: "Interested in",
    [FORM_SUMMARY_FIELD_KEYS.message]: "Message",
};

export const MEDIA_TYPE = {
    IMAGE: "image",
    VIDEO: "video",
};

export const TESTIMONIAL_TYPE = {
    TEXT: "text_view",
    IMAGE: "img_view",
    VIDEO: "video_view",
};

export const TESTIMONIAL_DISPLAY_TYPE = {
    LIST: "vertical_list",
    CAROUSEL: "horizontal_carousel",
};

export const THANKYOU_PAGE_SECTION_SLUGS: typeof THANKYOU_PAGE_SECTION_SLUGS_TYPE =
    _THANKYOU_PAGE_SECTION_SLUGS;

export const REDIRECTION_BTN_TYPES = {
    default: "default", // only used in purchase page
    telegram: "telegram",
    whatsapp: "whatsapp",
    custom: "custom",
    none: "none", // only used in opt-in page
};

export const DEFAULT_REDIRECTION_BTN_TEXTS = {
    default: "Continue",
    getInTouch: "Get in touch",
    brandedCommunity: "Access the community now!",
    telegramCommunity: "Open Telegram",
    recordedContent: "View content",
};

export const INITIAL_SECTIONS_DATA = {
    confirmation: {
        uuid: null,
        title: "",
        description: "",
    },
    redirection: {
        uuid: null,
        btnType: "",
        ctaText: "",
        redirectionUrl: "",
        isAutoRedirectEnabled: false,
    },
    upsell: {
        uuid: null,
        upsellOfferingId: "",
        title: "",
        subTitle: "",
        description: "",
        offerText: "",
        mediaType: MEDIA_TYPE.IMAGE,
        mediaImgSrc: "",
        mediaVideoSrc: "",
        purchaseText: "",
        cancelText: "",
    },
    testimonial: {
        uuid: null,
        title: "",
        listDesign: TESTIMONIAL_DISPLAY_TYPE.CAROUSEL,
        testimonialList: [],
    },
    analytics: {
        event: ANALYTICS_EVENTS.PURCHASE,
    },
};

export const GET_DEFAULT_SECTIONS_DATA = ({
    eventData,
}: {
    eventData?: IThankyouPagesArgs["eventData"];
}) => ({
    confirmation: {
        [THANKYOU_PAGE_TYPES.PURCHASE]: {
            title: "Purchase successful!",
            description:
                "You will get the purchase confirmation and invoice in your email",
        },
        [THANKYOU_PAGE_TYPES.OPTIN]: {
            title: "Thank you!",
            description:
                "We are glad that you are interested in our offering. We will reach out to you soon.",
        },
    },
    redirection: {
        [THANKYOU_PAGE_TYPES.PURCHASE]: {
            btnType: REDIRECTION_BTN_TYPES.default,
            ctaText: "",
            redirectionUrl: "",
            isAutoRedirectEnabled: false,
        },
        [THANKYOU_PAGE_TYPES.OPTIN]: {
            btnType: REDIRECTION_BTN_TYPES.none,
            ctaText: "",
            redirectionUrl: "",
            isAutoRedirectEnabled: false,
        },
    },
    upsell: {
        upsellOfferingId: "",
        title: "You might like this too",
        subTitle: "A one time offer that won’t ever come back again.",
        description:
            "You will never be able to get this at this price. If you miss it now, you miss it forever!",
        offerText: "Grab your offer now!",
        mediaType: MEDIA_TYPE.IMAGE,
        mediaImgSrc: eventData?.cover_image || "",
        mediaVideoSrc: eventData?.cover_video || "",
        purchaseText: "Buy Now",
        cancelText: "No, thanks",
    },
    testimonial: {
        title: "Testimonials",
        listDesign: TESTIMONIAL_DISPLAY_TYPE.CAROUSEL,
        testimonialList: [],
    },
});

export const DEFAULT_THANKYOU_PAGE_THEME_COLORS = {
    colors: {
        primary: "",
        pageBackground: "",
        pageBodyText: "",
        headerBackground: "",
        primaryButtonText: "",
        color1: "",
        color2: "",
        color3: "",
        color4: "",
        color5: "",
    },
    font: {
        slug: "",
        title: "",
        url: "",
    },
};

export const THANKYOU_STRINGS = {
    BOOKINGS_DUMMY_FOOTER_TEXT:
        "Booking details for the customer will be shared in this section",
    FORM_SUMMARY_FOOTER_TEXT:
        "Your leads will be shown a summary of their responses to the form here.",
};

export const PURCHASE_SUCCESS_ICON_PATH =
    "/static/images/thankyou/purchaseSuccess.svg";
export const BOOKING_SUCCESS_CHART_ICON_PATH =
    "/static/images/thankyou/bookingDetailsChart.svg";

export const LIGHT_TEXT_COLOR = "#FBFBFE";
export const DARK_TEXT_COLOR = "#272522";

export const CUSTOM_QUESTION_ANSWERS = {
    [CUSTOM_QUESTION_TYPES_ID.PARAGRAPH_TEXT]: {
        processAnswerText: (answer: string) => {
            return answer;
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.SINGLE_SELECT_DROPDOWN]: {
        processAnswerText: (answer: string) => {
            return answer;
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.MULTI_SELECT_DROPDOWN]: {
        processAnswerText: (answer: string[]) => {
            // TODO: need to figure out why are we doing `.slice(0, answerArg.length - 1)`
            return answer.join(",").slice(0, answer.length - 1);
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.CALENDAR]: {
        processAnswerText: (answer: string) => {
            return format(new Date(answer), "dd-mm-yy hh:mm a");
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.FILE]: {
        processAnswerText: (answer: string) => {
            return answer;
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.SHORT_TEXT]: {
        processAnswerText: (answer: string) => {
            return answer;
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.NUMBER]: {
        processAnswerText: (answer: string) => {
            return answer;
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.URL]: {
        processAnswerText: (answer: string) => {
            return answer;
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.GENDER]: {
        processAnswerText: (answer: string) => {
            return answer;
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.INDIAN_STATE]: {
        processAnswerText: (answer: string) => {
            return answer;
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.INDIAN_CITY]: {
        processAnswerText: (answer: string) => {
            return answer;
        },
    },
};

export const PULLER_AND_SPACE_OFFSET = 24;

export const UPSELL_DRAWER_AUTOOPEN_TIMER_MS = 2000;

// TODO @akhilesh: Need to move these constants to repov2 once Thank
// you page becomes of repov2 standards
// start
export const LOCAL_STORAGE_KEYS = {
    CURRENCY_SYMBOL: "currencySymbol",
    TOTAL_AMOUNT: "totalAmount",
    USER_NAME_LIST: "username_list",
};

/**
 * @deprecated Use the constant for this defined at repoV2/constants/common/storage/sessionStorage.ts
 */
export const SESSION_STORAGE_KEYS = session_storage_keys;

export const URL_SEARCH_PARAMS = {
    THANKYOU_PAGE_ID: "thankyou_page_id",
};
// end
